body {
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.customer-list {
  display: grid;
  grid-template-columns: max-content auto;
  gap: 10px 20px;
  justify-content: center; /* Add this to center the grid */
  padding: 0;
  margin: 0 auto; /* Centers the list in the parent container */
}

.customer-term {
  font-weight: bold;
  text-align: right;
}

.customer-detail {
  text-align: left;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loadingCircle {
  border: 5px solid #fff;
  border-top: 5px solid #0A3F60;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

dd {
  display: block;
  margin-inline-start: 0px;
}

